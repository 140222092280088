.header--logo img,
.footer--logo img {
    max-width: 10em;
    width: 100%;
}

.banner--content h2 {
    color: #091724!important;
}

.section--company {
    text-align: center;
    justify-content: center;
}

.section--company p {
    margin-bottom: 30px;
}

.service--icon img {
    max-width: 10em;
    width: 100%;
}

.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transition: transform .25s;
}

.owl-carousel .owl-nav .owl-next:hover,
.owl-carousel .owl-nav .owl-prev:hover {
    transform: scale(1.15);
    transition: transform .25s;
}

.contact-info--wrapper {
    border-radius: 3px 3px 0px 0px;
}

.contact-info--content h2 {
    font-size: 20px;
    font-weight: 600!important;
}

.features-grid--content h3 {
    font-weight: 600;
}

.features-grid--icon img {
    max-width: 1.3em;
    width: 100%;
    transition: .50s ease-in-out;
}


/* 
.vc--parent:hover .features-grid--icon img {
    transform: scale(1.2);
    transition: .50s ease-in-out;
} */

.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: 0;
}

.line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50px;
    left: 50%;
    background: #e8edef;
    overflow: hidden;
}

.line::after {
    content: "";
    display: block;
    position: absolute;
    height: 10vh;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, #e8edef 0%, #a2b4bc 75%, #90adb9 100%);
    -webkit-animation: drop 4s 0s infinite;
    animation: drop 4s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

.line:nth-child(1) {
    margin-left: -40%;
}

.line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.line:nth-child(3) {
    margin-left: 0%;
}

.line:nth-child(2) {
    margin-left: -20%;
}

.line:nth-child(4) {
    margin-left: 20%;
}

.line:nth-child(5) {
    margin-left: 40%;
}

.line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

@-webkit-keyframes drop {
    0% {
        top: -50%;
    }
    100% {
        top: 110%;
    }
}

@keyframes drop {
    0% {
        top: -50%;
    }
    100% {
        top: 110%;
    }
}

.slide-home a {
    color: #091724!important;
}

.page-header--breadcrumb .breadcrumb>li+li:before {
    content: '\e876';
    font-size: 9px;
    font-family: 'Linearicons-Free';
    color: #fff;
}

#services .banner--content h2 {
    color: #1e9ff2!important;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
}

#services .banner--content p {
    color: #e8edef!important;
}

#services .banner--content a {
    color: #091724!important;
}

#services .btn {
    padding: 10px 22px!important;
    font-size: 16px;
    font-weight: 600;
}

#servicesLogo img {
    max-width: 18em;
    width: 100%;
    margin-bottom: 1.5em;
}


/* @-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; } 
  }
  @-moz-keyframes fadeIn {
  0% { opacity: 0;}
  100% { opacity: 1; }
  }
  @-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
  }
  @keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.fadeIn{
  -webkit-animation: fadeIn 3s ease-in-out;
  -moz-animation: fadeIn 3s ease-in-out;
  -o-animation: fadeIn 3s ease-in-out;
  animation: fadeIn 3s ease-in-out;
  -webkit-animation-delay:1s;
  -moz-animation-delay:1s;
  -o-animation-delay:1s;
  animation-delay:1s;
} */

@-webkit-keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 7));
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 7));
    }
}

.slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: auto;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 80%;
    margin-bottom: 100px;
    border-radius: 3px;
}

.slider::before,
.slider::after {
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}

.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slider .slide-track {
    -webkit-animation: scroll 40s linear infinite;
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
}

.slider .slide {
    height: auto;
    width: 250px;
    vertical-align: middle;
    padding: 20px 0px 20px 0px;
}

.slide img {
    max-width: 10em;
    width: 100%;
    vertical-align: middle;
}

#aboutCompany h1 {
    font-size: 42px;
    margin-bottom: 1.3em;
    color: #091724;
}

#aboutCompany p {
    padding-right: 1.5em;
    line-height: 1.5em;
}

#aboutCompany a {
    font-size: 15px;
    font-weight: 700;
}

#aboutCompany span {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    transition: 0.35s;
}

#aboutCompany .btn-about:hover span {
    margin-left: 10px;
    transition: 0.35s;
}

.pd--100-about {
    padding: 100px 0 0 0;
}

.space-50 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.space-100 {
    margin-top: 50px;
    margin-bottom: 50px;
}

#menuBlock {
    background-color: #e8edef;
    border-radius: 10px;
}

#menuBlock img {
    max-width: 4em;
    width: 100%;
}

#fastMenu {
    text-align: center;
}

#fastMenu .col-menu {
    max-width: 18%;
    width: 18%;
    padding: 1.5em;
    margin: 10px;
    text-align: center;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.01);
    transition: transform 0.25s, box-shadow 0.25s;
}

#fastMenu .col-menu:hover {
    transform: scale(1.1);
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.25s, box-shadow 0.25s;
}

#servicesBlock {
    background-color: #e8edef;
    text-align: center;
}

.text-services {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.text-slider {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

#servicesBlock .col-services {
    background-color: #fff;
    width: 23%;
    display: inline-block;
    position: relative;
    margin: 0px 10px;
    border-radius: 10px;
    padding: 0em 2.5em 3em 2.5em;
    text-align: center;
    line-height: 1.5em;
}

#servicesBlock img {
    max-width: 7em;
    width: 100%;
    background-color: #ffffff;
    margin-top: -2em;
    padding: 1em;
    border-radius: 8px;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

#servicesBlock .section--title h2 {
    margin-bottom: 20px;
}

#servicesBlock h3 {
    color: #091724;
    line-height: 1.5em;
}

#servicesBlock p {
    margin-bottom: 1.5em;
}

#servicesBlock a {
    font-size: 15px;
    font-weight: 600;
}

#servicesBlock a span {
    margin-left: 5px;
    font-weight: 600;
    font-size: 13px;
    transition: 0.25s;
}

#servicesBlock a:hover span {
    margin-left: 10px;
    transition: 0.25s;
}

#thumbBlock h2 {
    color: #fff;
    margin-bottom: 20px;
}

#thumbBlock p {
    color: #fff;
    margin-bottom: 50px;
}

#thumbBlock a {
    color: #fff;
    font-size: 15px;
    padding: 12px 10px 10px 5px;
    border-radius: 50px;
    transition: 0.35s;
}

#thumbBlock a:hover {
    color: #091724;
    background-color: #fff;
    border-radius: 50px;
    transition: 0.35s;
}

#thumbBlock a span {
    color: #091724;
    font-weight: 600;
    background-color: #fff;
    border-radius: 50px;
    padding: 10px;
    margin-right: 10px;
}

.tec-block {
    padding: 5em;
    margin: 0% 1% 0% 1%;
}

.tecnology-carousel {
    padding-left: 25px;
    border-bottom: 1px solid #0917243b;
    padding-bottom: 20px;
}

.tecnology-carousel img {
    max-width: 8em;
    padding: 15px;
    width: 100%;
    margin: 0px 15px 0px 15px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.038);
    transition: 0.35s;
    background-color: #e8edef9e;
    border-radius: 10px;
}

.tecnology-carousel img:hover {
    transform: scale(1.1);
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    transition: 0.35s;
}

.tecnology-class {
    margin-bottom: 50px;
}

.tecnology-class h3 {
    color: #091724;
    font-weight: 500;
}

.tecnology-class span {
    font-size: 18px;
    font-weight: 600;
    margin-left: 0px;
    transition: 0.35s;
}

.tecnology-class:hover span {
    color: #3997ee;
    margin-left: 10px;
    transition: 0.35s;
}

#history {
    padding-bottom: 15em;
    background-color: #e8edef;
}

#history .personalizado {
    padding: 2em;
}

#history .col3 h2 {
    line-height: 1.5em;
}

#colsAbout {
    background-color: #091724;
    padding-top: 0px;
    padding-bottom: 150px;
}

#colsAbout .cols-about {
    width: 28%;
    display: inline-block;
    background-color: #fff;
    margin-top: -13em;
    padding: 2.5em;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    text-align: center;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.cols-about img {
    max-width: 8em;
    width: 100%;
    margin: 10px;
}

.cols-about h3 {
    color: #091724;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 5px;
    margin-bottom: 30px;
}

.cols-about p {
    font-size: 13px;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.pd-top-4 {
    padding-top: 4em;
}

.pd-top-4 h2 {
    line-height: 2em;
    font-size: 35px;
    color: #091724;
}

#contactSys h2 {
    text-align: center;
    color: #091724;
}

#contactSys p {
    text-align: center;
}

@media (max-width: 1199px) {
    #fastMenu .col-menu {
        margin: 0px 5px;
    }
    #servicesBlock .col-services {
        width: 22%;
    }
}

@media (max-width: 990px) {
    #servicesBlock .col-services {
        width: 46%;
        margin: 25px 15px;
    }
    #pageContent {
        text-align: center;
    }
    #pageContent .middle-col {
        display: inline-block;
    }
    #pageContent .middle-col img {
        width: 80%;
        height: auto;
    }
    .tecnology-carousel img {
        max-width: 7em;
    }
    #menuBlock h4 {
        font-size: 14px;
    }
    #colsAbout .cols-about {
        padding: 2em;
    }
    .cols-about img {
        max-width: 7em;
    }
    #history img {
        width: 80%;
    }
    #history {
        text-align: center;
    }
    #placeBlock img {
        width: 70%;
    }
    #placeBlock {
        text-align: center;
    }
}

@media (max-width: 749px) {
    #servicesBlock .col-services {
        width: 45%;
        margin: 20px 15px;
    }
    .tecnology-carousel img {
        margin: 10px 15px 10px 15px;
    }
    #menuBlock img {
        max-width: 3em;
    }
    #menuBlock h4 {
        font-size: 13px;
    }
}

@media (max-width: 599px) {
    #servicesBlock .col-services {
        width: 44%;
        margin: 25px 15px;
    }
    .tecnology-carousel img {
        max-width: 6em;
    }
    #fastMenu .col-menu {
        max-width: 97%;
        width: 47%;
        margin: 1.5%;
    }
    #menuBlock h4 {
        font-size: 14px;
    }
    #colsAbout {
        text-align: center;
        padding-bottom: 50px;
    }
    #colsAbout .cols-about {
        width: 90%;
        margin-top: 3em;
        padding: 3em;
    }
    .cols-about h3 {
        font-size: 25px;
    }
    .cols-about p {
        font-size: 15px;
    }
    #history {
        padding-bottom: 0em;
    }
    .tecnology-carousel img {
        margin: 10px 5px;
    }
    .tec-block {
        padding: 2em;
    }
    .tecnology-carousel {
        padding-left: 5px;
    }
    #hidexs {
        display: none;
    }
}

@media (max-width: 499px) {
    #servicesBlock .col-services {
        width: 80%;
        margin: 1em;
    }
}